import * as React from 'react'
import { Container, VisuallyHidden, Heading } from '@chakra-ui/react'
import Layout from '../components/layout'
import Seo from '../components/seo'

const ConfirmationPage = () => {
  return (
    <Layout>
      <Seo title="Confirmation" />

      <VisuallyHidden as="h1">Confirmation</VisuallyHidden>
      <Container py={20}>
        <Heading as="h2" size="lg" mb={4}>
          Thank you, your preferences have been saved.
        </Heading>
      </Container>
    </Layout>
  )
}

export default ConfirmationPage
